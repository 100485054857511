<template>
  <div class="row">
    <b-modal :id="formId" :size="formWidth">
      <template #modal-title>
        <div v-if="formState == 'Update'">Update Directions #{{ formData.id }}</div>
        <div v-if="formState == 'Create'">Create Directions</div>
      </template>

      <EditDirections v-if="formState == 'Update'" :key="formKey" :data="formData" :gridApi="formGridApi"
                      :modalFormId="formId" @close="closeForm"/>


      <CreateDirections v-if="formState == 'Create'" :key="formKey" :gridApi="formGridApi" :modalFormId="formId"
                        @close="closeForm"/>

      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>


    <div class="col-sm-12">
      <!--            <div class="row">-->
      <!--                <h1 style="text-align: center;">Proyecto Vue Google Map</h1>-->
      <!--                <Maps :mapkey="125"/>-->
      <!--            </div>-->


    </div>
    <div class="col-sm-12">
      <AgGridTable :key="tableKey" :cacheBlockSize="cacheBlockSize" :columnDefs="columnDefs"
                   :maxBlocksInCache="maxBlocksInCache"
                   :pagination="pagination" :paginationPageSize="paginationPageSize" :rowData="rowData"
                   :rowModelType="rowModelType"
                   :url="url" className="ag-theme-alpine" domLayout='autoHeight'
                   rowSelection="multiple" @gridReady="onGridReady">
        <template #header_buttons>
          <div v-if="!routeData.meta.hideCreate" class="btn btn-primary" @click="openCreate"><i
              class="fa fa-plus"></i> Nouveau
          </div>
        </template>

      </AgGridTable>

    </div>
  </div>
</template>


<script>

import moment from 'moment'


export default {
  name: 'DirectionsView',
  components: {
    DataTable: () => import( "@/components/DataTable.vue"),
    AgGridTable: () => import("@/components/AgGridTable.vue"),
    CreateDirections: () => import( "./CreateDirections.vue"),
    EditDirections: () => import( "./EditDirections.vue"),
    DataModal: () => import("@/components/DataModal.vue"),
    AgGridBtnClicked: () => import("@/components/AgGridBtnClicked.vue"),
    Maps: () => import("../Sites/Maps.vue"),
  },
  data() {

    return {
      formId: "directions",
      formState: "",
      formData: {},
      formWidth: 'lg',
      formGridApi: {},
      formKey: 0,
      tableKey: 0,
      url: 'http://127.0.0.1:8000/api/directions-Aggrid1',
      table: 'directions',
      requette: 0,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      rowModelType: null,
      pagination: true,
      paginationPageSize: 25,
      cacheBlockSize: 10,
      maxBlocksInCache: 1,
    }
  },

  computed: {
    routeData: function () {
      let router = {meta: {}}
      if (window.router) {
        try {
          router = window.router;
        } catch (e) {
        }
      }


      return router
    },
    taille: function () {
      let result = 'col-sm-12'
      if (this.filtre) {
        result = 'col-sm-9'
      }
      return result
    },
  },
  watch: {
    'routeData': {
      handler: function (after, before) {
        this.gridApi.setFilterModel(null)
        this.gridApi.refreshServerSide()
      },
      deep: true
    },
  },
  created() {
    this.url = this.axios.defaults.baseURL + '/api/directions-Aggrid1',
        this.formId = this.table + "_" + Date.now()
    this.rowBuffer = 0;
    this.rowModelType = 'serverSide';
    this.cacheBlockSize = 50;
    this.maxBlocksInCache = 2;

  },
  beforeMount() {
    this.columnDefs =
        [
          {
            field: "id",
            sortable: true,
            filter: 'agTextColumnFilter',
            filterParams: {suppressAndOrCondition: true,},
            hide: true,
            headerName: '#Id',
          },
          {
            field: null,
            headerName: '',
            suppressCellSelection: true,
            width: 40,
            pinned: 'left',
            cellRendererSelector: params => {
              return {
                component: 'AgGridBtnClicked',
                params: {
                  clicked: field => {
                    this.showForm('Update', field, params.api)
                  },
                  render: `<div class="btn btn-primary  d-flex justify-content-center align-items-center" style="width:100%;height:100%;color:#fff;border-radius:5px;text-align:center;cursor:pointer">  <i class="fa-solid fa-pen-to-square "></i></div>`
                }
              };
            },

          },


          {
            field: "libelle",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'libelle',
          },


          {
            field: "code",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'code',
          },
          {
            field: "created_at",
            sortable: true,
            filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
            headerName: 'Créer le',
            valueFormatter: params => {
              let retour = params.value
              try {
                if (retour) {
                  retour = moment(params.value).format('DD/MM/YYYY à HH:mm')

                } else {
                  retour = 'Date inconnue'
                }
              } catch (e) {

              }
              return retour
            }
          },


          // {
          //     field: "identifiants_sadge",
          //     sortable: true,
          //     filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          //     headerName: 'identifiants_sadge',
          // },


          // {
          //     field: "creat_by",
          //     sortable: true,
          //     filter: 'agTextColumnFilter', filterParams: {suppressAndOrCondition: true,},
          //     headerName: 'creat_by',
          // },


        ];


  },
  mounted() {
    if (this.requette > 0) {
      // this.$store.commit('setIsLoading', true)
    }


  },
  methods: {
    closeForm() {
      try {
        this.gridApi.refreshServerSide()
      } catch (e) {

      }
    },
    openCreate() {
      this.showForm('Create', {}, this.gridApi)
    },
    showForm(type, data, gridApi, width = 'lg') {
      this.formKey++
      this.formWidth = width
      this.formState = type
      this.formData = data
      this.formGridApi = gridApi
      this.$bvModal.show(this.formId)
    },
    onGridReady(params) {
      console.log('on demarre', params)
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.isLoading = false
      this.gridApi.sizeColumnsToFit();

    },
  }
}
</script>
